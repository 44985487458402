<template>
  <div class="gradus">
    <hdimage></hdimage>
  </div>
</template>

<script>
 import hdimage from '../components/hdimage.vue'

export default {
  components: {
     'hdimage': hdimage
  }
}
</script>